<template>
  <v-popover :disabled="!options">
    <button class="downloads-btn btn px-4 pr-sm-5">
      {{$t('Download')}}
      <Icon class="position-absolute" family="fas" name="chevron-down" />
    </button>

    <template #popover>
      <div class="downloads__download-button-popover">
        <ul v-for="(option, optionIndex) in options" :key="optionIndex">
          <p>{{ option.title }}</p>

          <li
            v-for="(link, linkIndex) in option.links"
            :key="linkIndex"
            v-close-popover
          >
            <a
              :href="link.url ? link.url :'#'"
              :target="link.url ? '_blank' :'_self'"
              @click="onDownload($event, link)"
            >
              <Icon :family="link.iconFamily || 'fab'" :name="link.icon" />

              <div class="d-flex align-items-center">
                <h4>{{ link.title }}</h4>
                <span v-if="link.description">{{ link.description }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'DownloadButton',
  components: { Icon },
  props: {
    options: {
      required: true,
      type: Array,
    },
  },
  methods: {
    onDownload(e, link) {
      e.preventDefault();
      this.$emit('download', link);
    }
  }
};
</script>

<style lang="scss" scoped>
button.downloads-btn {
  padding-left: 1.125rem;
  padding-right: 2.25rem;
  position: relative;
  color: var(--color-primary);
  border: 1px solid var(--border-color);

  i {
    color: var(--text-muted);
    font-size: 14px;
    right: 16px;
    transform: translateY(-50%);
    top: 50%;
  }
}
</style>
