<template>
  <div class="downloads-wrapper">
    <section class="section-header">
      <div class="container-lg">
        <h2>
          <Icon class="mr-3" family="fal" name="download" />
          {{ $t('Downloads') }}
        </h2>
      </div>
    </section>

    <main role="main" class="main-wrapper">
      <Loader full :loading="isLoading" />

      <div class="filter-row mb-3">
        <InputBox
          v-model="searchStr"
          class="pl-0"
          :prepends="[{ icon: 'search' }]"
          :placeholder="$t('Filter downloads...')"
          :appends="[
            searchStr ? { icon: 'times', action: () => (searchStr = '') } : {},
          ]"
          form-size="sm"
          rounded
        />
      </div>

      <div>
        <div v-if="!isLoading">
          <div
            v-if="!downloadSections.length && !otherDownloads.length"
            class="empty-state"
          >
            {{ $t('There is no results, try cleaning filters.') }}
          </div>

          <section
            v-for="(section, index) in downloadSections"
            :key="index"
            :class="{
              ['has-divider']:
                index > 0 &&
                (downloadSections[index - 1].sectionTitle !==
                  section.sectionTitle ||
                  searchStr),
            }"
          >
            <div
              :ref="section.id"
              class="section-content-wrap"
              :class="{ ['section-active']: currentSection === section.id }"
            >
              <h4
                v-if="
                  !searchStr &&
                  (index === 0 ||
                    downloadSections[index - 1].sectionTitle !==
                      section.sectionTitle)
                "
                class="section-title"
              >
                {{ $t(section.sectionTitle) }}
              </h4>

              <div
                class="
                  d-flex
                  flex-column flex-sm-row
                  justify-content-between
                  align-items-center
                "
              >
                <div
                  class="
                    d-flex
                    align-items-start align-items-md-center
                    mr-0 mr-sm-3
                  "
                >
                  <Icon class="large-icon" family="fad" :name="section.icon" />

                  <div>
                    <h4>{{ $t(section.title) }}</h4>
                    <span>{{ $t(section.description) }}</span>
                    <small v-if="section.info">* {{ $t(section.info) }}</small>
                  </div>
                </div>

                <div class="actions-divider">
                  <a
                    v-if="section.documentationUrl"
                    class="mr-0 mr-sm-3"
                    :href="section.documentationUrl"
                    target="_blank"
                    >{{ $t('Documentation') }}</a
                  >
                  <DownloadButton
                    :options="section.downloadOptions"
                    @download="onDownload"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>

        <div
          v-if="!isLoading"
          ref="other"
          class="other-downloads"
          :class="{ ['section-active']: currentSection === 'other' }"
        >
          <h4 v-if="otherDownloads.length">{{ $t('Other downloads') }}</h4>

          <ul>
            <li
              v-for="(link, index) in otherDownloads"
              :key="index"
            >
              <a href="#" @click="() => onDownload(link)"
                >{{ $t(link.label) }}
              </a>
              <p v-if="link.description" class="m-0">
                {{ $t(link.description) }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import InputBox from '@/components/InputBox';
import Loader from 'common/components/Loader';

import DownloadButton from '../components/Downloads/DownloadButton';

import Fuse from 'fuse.js';

import { downloadSections, otherDownloads } from '@/constants/downloadsPage';

export default {
  name: 'Downloads',
  components: {
    Icon,
    DownloadButton,
    InputBox,
    Loader,
  },
  data() {
    return {
      searchStr: '',
      downloadLinks: {},
      sectionFuse: new Fuse(downloadSections, {
        keys: ['title', 'description'],
        distance: 200,
        threshold: 0.3,
      }),
      otherFuse: new Fuse(otherDownloads, {
        keys: ['label', 'url'],
        distance: 200,
        threshold: 0.3,
      }),
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['core/getDownloads'];
    },
    downloadSections() {
      if (this.searchStr) {
        return this.sectionFuse.search(this.searchStr).map((s) => s.item);
      } else {
        return downloadSections;
      }
    },
    otherDownloads() {
      if (this.searchStr) {
        return this.otherFuse.search(this.searchStr).map((s) => s.item);
      } else {
        return otherDownloads;
      }
    },
    currentSection() {
      return this.$route.params.section;
    },
  },
  async mounted() {
    const response = await this.$store.dispatch('core/getDownloads');

    if (!response.ok) return;

    response.data.setting.forEach((link) => {
      this.downloadLinks[link.param] = link.value;
    });
  },
  methods: {
    onDownload(link) {
      const url = this.downloadLinks[link.id];

      if (!url || typeof url !== 'string') return;

      window.open(url);
    },
  },
};
</script>
