var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"downloads-wrapper"},[_c('section',{staticClass:"section-header"},[_c('div',{staticClass:"container-lg"},[_c('h2',[_c('Icon',{staticClass:"mr-3",attrs:{"family":"fal","name":"download"}}),_vm._v(" "+_vm._s(_vm.$t('Downloads'))+" ")],1)])]),_c('main',{staticClass:"main-wrapper",attrs:{"role":"main"}},[_c('Loader',{attrs:{"full":"","loading":_vm.isLoading}}),_c('div',{staticClass:"filter-row mb-3"},[_c('InputBox',{staticClass:"pl-0",attrs:{"prepends":[{ icon: 'search' }],"placeholder":_vm.$t('Filter downloads...'),"appends":[
          _vm.searchStr ? { icon: 'times', action: function () { return (_vm.searchStr = ''); } } : {} ],"form-size":"sm","rounded":""},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}})],1),_c('div',[(!_vm.isLoading)?_c('div',[(!_vm.downloadSections.length && !_vm.otherDownloads.length)?_c('div',{staticClass:"empty-state"},[_vm._v(" "+_vm._s(_vm.$t('There is no results, try cleaning filters.'))+" ")]):_vm._e(),_vm._l((_vm.downloadSections),function(section,index){
        var _obj, _obj$1;
return _c('section',{key:index,class:( _obj = {}, _obj['has-divider'] = index > 0 &&
              (_vm.downloadSections[index - 1].sectionTitle !==
                section.sectionTitle ||
                _vm.searchStr), _obj )},[_c('div',{ref:section.id,refInFor:true,staticClass:"section-content-wrap",class:( _obj$1 = {}, _obj$1['section-active'] = _vm.currentSection === section.id, _obj$1 )},[(
                !_vm.searchStr &&
                (index === 0 ||
                  _vm.downloadSections[index - 1].sectionTitle !==
                    section.sectionTitle)
              )?_c('h4',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.$t(section.sectionTitle))+" ")]):_vm._e(),_c('div',{staticClass:"\n                d-flex\n                flex-column flex-sm-row\n                justify-content-between\n                align-items-center\n              "},[_c('div',{staticClass:"\n                  d-flex\n                  align-items-start align-items-md-center\n                  mr-0 mr-sm-3\n                "},[_c('Icon',{staticClass:"large-icon",attrs:{"family":"fad","name":section.icon}}),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t(section.title)))]),_c('span',[_vm._v(_vm._s(_vm.$t(section.description)))]),(section.info)?_c('small',[_vm._v("* "+_vm._s(_vm.$t(section.info)))]):_vm._e()])],1),_c('div',{staticClass:"actions-divider"},[(section.documentationUrl)?_c('a',{staticClass:"mr-0 mr-sm-3",attrs:{"href":section.documentationUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('Documentation')))]):_vm._e(),_c('DownloadButton',{attrs:{"options":section.downloadOptions},on:{"download":_vm.onDownload}})],1)])])])})],2):_vm._e(),(!_vm.isLoading)?_c('div',{ref:"other",staticClass:"other-downloads",class:( _obj = {}, _obj['section-active'] = _vm.currentSection === 'other', _obj )},[(_vm.otherDownloads.length)?_c('h4',[_vm._v(_vm._s(_vm.$t('Other downloads')))]):_vm._e(),_c('ul',_vm._l((_vm.otherDownloads),function(link,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"#"},on:{"click":function () { return _vm.onDownload(link); }}},[_vm._v(_vm._s(_vm.$t(link.label))+" ")]),(link.description)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t(link.description))+" ")]):_vm._e()])}),0)]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }